.navbar{
    background-color: rgba(0, 0, 0, 0.54);
    box-shadow: rgb(0, 0, 0) 0px 0px 10px 0px;
    height: 2rem;
}

.start-button{
    background: rgba(47, 213, 235, 0.664) ;
    box-shadow: rgba(47, 213, 235, 0.664) 0px 0px 4px 1px;
}

.nav-icon{
    height: 1.5rem;
    width: 1.5rem;
    margin: auto 0.125rem;

    cursor: pointer;
}

.nav-icon-right{
    height: 1rem;
    width: 1rem;
    margin: auto 0.4rem;

    cursor: pointer;
}

.desktop-choice{
    margin: auto 0.075rem;
}
.desktop-choice.selected{
    background-color: rgba(42, 95, 240, 0.192);
    border-top: 2px solid rgba(0, 110, 255, 0);
    border-bottom: 2px solid rgba(0, 110, 255, 0.5);
}