.list{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 5px;

    background: #ffffff8d;
}

.list > div{
    position: relative;
}

.list > div > img{
    background: #fff;
    margin: 0.3rem;
    width: 2rem;
    height: 2rem;    
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
    transition: transform 0.2s cubic-bezier(.43,.76,.69,.94);
}

.list > div > img:hover{
    cursor: pointer;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
    transform: translate(0px, -8px);
    transition: transform 0.2s cubic-bezier(.27,1.54,.8,1.07);
}

.list .description {
    position: absolute;
    font-size: 0.8rem;
    color: #fff;
    background: #0000008c;
    border-radius: 5px;
    padding: 2px 5px;
  }
  
