.help-commands table {
    width: 80%;
    border-collapse: collapse;
  }

  .help-button{
    cursor: pointer;
  }

.help-commands th,
.help-commands td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }

.help-commands th {
    background-color: #4CAF50;
    color: white;
  }

.help-commands tr:nth-child(even) {
    background-color: #f2f2f22a;
  }

.help-commands tr:hover {
    background-color: #dddddd6c;
  }

.help-commands tr > td:nth-child(1) {
    color: #52baff;
    text-align: center;
  }


.project{
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* blur background*/
    backdrop-filter: blur(5px);
}

.projects tr:nth-child(even) {
    background-color: #f2f2f22a;
  }

table.projects{
    margin: 8px 0 8px 5px;
    width: 80%;
    border-collapse: collapse;
    padding: 0.5rem;
}

.projects thead{
    color: #d5d5d5;
    font-weight: bold;

    border-bottom : 1px solid #d5d5d5;
}

.projects thead td{
    padding: 0.5rem;
    text-align: center;
}

.projects tbody td{
    padding: 0.1rem;
}


.project a{
    margin: 5px 0 5px 10px;
    color: #52baff;
    text-decoration: none;
    cursor: pointer;
    font-size: 0.8rem;
}

.project a:hover{
    text-decoration: underline;
}

.project .stars p{
    color: #bedc13;
    font-size: 0.8rem;

    text-align: center;
}

.project .description{
    color: #d5d5d5;
    font-size: 0.8rem;
}

/* Theme 1 */
.theme-1{
    color: azure;
}

.theme-1 .folders-names{
    color: rgb(29, 109, 214);
}

.theme-1 .files-names{
    color: white;
}  

input{
    
}

/* Theme 2 */
.theme-2{
    color: azure;
}

.theme-2 .folders-names{
    color: aqua;
}

.theme-2 .files-names{
    color: white;
}

/* Theme 3 */
.theme-3{
    color: azure;
}

.theme-3 .folders-names{
    color: aqua;
}

.theme-3 .files-names{
    color: white;
}

/* Theme 2 */
.theme-4{
    color: azure;
}

.theme-4 .folders-names{
    color: aqua;
}

.theme-4 .files-names{
    color: white;
}


